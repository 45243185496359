import preview from '../assets/images/Homepage-Social-Post-Showcase-LINKEDIN.png';

import '../styles/Homepage.scss';
import MetaDecorator from './MetaDecorator';

export default function Homepage(props) {
  return (
    <section className="homepage">
      <MetaDecorator title="Courageous Showcase" description="" image={preview} />
      <div className="header">
        <h1>
          Bold Stories.
          <br /> New Horizons.
        </h1>

        <p>
          2023 has been a year of{' '}
          <span style={{ fontFamily: 'Lato Bold' }}>growth and transformation.</span> To celebrate,
          we’ve collected{' '}
          <span style={{ fontFamily: 'Lato Bold' }}>
            twelve of our favorite campaigns from the past year
          </span>{' '}
          to share with our friends.
        </p>
        <p>
          This work is a testament to{' '}
          <span style={{ fontFamily: 'Lato Bold' }}>
            finding and expressing our truest selves, building and nurturing communities, and
            braving the unknown.
          </span>
        </p>

        <p>
          We are <span style={{ fontFamily: 'Lato Bold' }}>Proud.</span> We are{' '}
          <span style={{ fontFamily: 'Lato Bold' }}>Grateful.</span> We are{' '}
          <span style={{ fontFamily: 'Lato Bold' }}>
            Courageous – the brand studio for Warner Bros. Discovery.
          </span>
        </p>
        <p>Thank you for another wonderful year!</p>
      </div>
      <div className="grid-container">
        <div className="value-grid">
          {props.data.map((item, index) => {
            return (
              <div
                className={`square s${index + 1}`}
                id={`${item.theme}`}
                key={index}
                onClick={(e) => {
                  e.preventDefault();
                  console.log('index', index);
                  props.setCurrentPage(index);
                  window.location = `/${item.theme}`;
                }}
              >
                <img src={item.poster} alt="poster" />
                <video src={item.loop} poster={item.poster} autoPlay muted loop playsInline />

                <div className="video-info">
                  <p className="title">{item.title}</p>
                  <p className="brand">{item.brand}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
