import React from 'react';
import '../styles/introVid.scss';
import useWidthSetter from '../customHooks/useWidthSetter';

const IntroVideo = () => {
  const width = useWidthSetter();
  return (
    <div className="introVid">
      <div className="introVid__videoPlayer">
        <video
          autoPlay
          muted
          playsInline
          loop
          className="introVid__videoPlayer__videoBG"
          // ref={videoLoopRef}
        >
          <source
            src={
              width >= 720
                ? 'https://courageous-cdn-media-bucket.s3.amazonaws.com/courageous-showcase-2023/headerBgLoop.mp4'
                : 'https://courageous-cdn-media-bucket.s3.amazonaws.com/courageous-showcase-2023/headerBgLoopM.mp4'
            }
            type="video/mp4"
          />
          Your browser does not support HTML5 video.
        </video>
      </div>
    </div>
  );
};

export default IntroVideo;
