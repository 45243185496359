import amicaStill from '../assets/images2023/amicaStill.jpg';
import abbottStill from '../assets/images2023/abbottStill.jpg';
import statefarmStill from '../assets/images2023/statefarmStill.jpg';
import nevadaStill from '../assets/images2023/nevadaStill.jpg';
import desCanStill from '../assets/images2023/desCanStill.jpg';
import cadillacStill from '../assets/images2023/cadillacStill.jpg';
import expediaStill from '../assets/images2023/expediaStill.jpg';
import jaguarStill from '../assets/images2023/jaguarStill.jpg';
import lorealStill from '../assets/images2023/lorealStill.jpg';
import humanaStill from '../assets/images2023/humanaStill.jpg';
import sleepNumberStill from '../assets/images2023/sleepNumberStill.jpg';
import intelStill from '../assets/images2023/intelStill.jpg';

export const data = [
  {
    theme: '01',
    video: '866091972',

    loop: 'https://courageous-cdn-media-bucket.s3.amazonaws.com/courageous-showcase-2023/AMICA+LOOP.mp4',
    poster: amicaStill,
    preview: amicaStill,
    brand: 'Amica',
    title: 'What You Leave Behind: The Drumset',
    description:
      'Anyone who has lost a loved one knows that the smallest object can remind us of everything they stood for. Their values. Their dreams. Their impact on the world around them.<br/><br/>Chris Lotito and his father spent every day bonding over a shared love of music, behind a 1966 Ludwig Hollywood drum set. But not long after Chris lost his father to ALS, the drums were lost as well.<br/><br/>For the next 20 years, Chris searched for that set. Then, on his late father’s birthday, Chris made an amazing discovery.',
    quote: '',
    released: true
  },
  {
    theme: '02',
    // video: '863204816',
    visitUrl: 'https://abbott-discovery.com',
    // loop:
    //   'https://courageous-cdn-media-bucket.s3.amazonaws.com/courageous-showcase-2022/MILESTONE.mp4',
    poster: abbottStill,
    preview: abbottStill,
    brand: 'Abbott',
    title: 'The Virus Hunt',
    description:
      '6.95 million people worldwide have died in the COVID-19 pandemic. Scientists around the globe, struggling to document, track, and respond to viral developments, always seem one step behind in their fight against a constantly evolving enemy.<br/><br/>Abbott saw an opportunity to connect these siloed local knowledge bases through a global network as the first line of defense against emerging pathogens. And thus, the Abbott Pandemic Defense Coalition was born.<br/><br/>Across Colombia, South Africa, New Delhi, and Chicago, each film in this groundbreaking campaign captures a kaleidoscopic portrait of the real people behind this mission—each expert, locale, and personal story is interwoven to tell a timely, life-affirming story of what unites us in our common quest for survival.',
    quote: '',
    released: false
  },
  {
    theme: '03',
    video: '894306297',
    // loop: 'https://courageous-cdn-media-bucket.s3.amazonaws.com/courageous-showcase-2022/GILEAD.mp4',
    poster: lorealStill,
    preview: lorealStill,
    brand: 'L’Oreal',
    title: 'Women of Worth: Judaline',
    description:
      "Every year, L'Oréal Paris launches a nationwide search for ten extraordinary non-profit leaders to be named <span style='font-family: Lato Bold'>Women of Worth</span> honorees, each to be awarded a $25,000 donation and an opportunity to tell her story.<br/><br/><span style='font-family: Lato Bold'>Judaline Cassidy</span> is the Founder of <span style='font-family: Lato Bold'>Tools & Tiaras</span>, a weekend and summer program with a mission to show girls that jobs don’t have genders. Through group projects and hands-on learning, Judaline is teaching these girls valuable trade skills that open a new world of career possibilities for the future."
  },
  {
    theme: '04',
    video: '847065595',
    // loop: 'https://courageous-cdn-media-bucket.s3.amazonaws.com/courageous-showcase-2022/LENOVO.mp4',
    // preview: charlesSchwabPreview,
    poster: nevadaStill,
    brand: 'Travel Nevada',
    title: 'The Sounds of the Loneliest Road in America',
    description:
      "Along your drive on the transcontinental U.S. Route 50, you’ll encounter a stretch that’s been dubbed <span style='font-family: Lato Bold'>“the loneliest road in America”</span> as you pass through Central Nevada.<br><br>Cowboy Roy is an audio engineer searching for unique soundscapes around the world. For him, the opportunity to explore these haunting new “silent” spaces was simply irresistible.<br><br>Watch the unique character of this lonely landscape come to life as Roy explores Nevada on the road less traveled.",
    quote: ''
  },
  {
    theme: '05',
    video: '856528379',
    loop: 'https://courageous-cdn-media-bucket.s3.amazonaws.com/courageous-showcase-2023/STATE+FARM+LOOP.mp4',
    preview: statefarmStill,
    poster: statefarmStill,
    brand: 'State Farm',
    title: 'Slam Dunk Trivia Time',
    description:
      "How do you capture the spectacular look and feel of a series like HBO’s <span style='font-family: Lato Bold'>Winning Time?</span> Though an 80’s-style basketball trivia show, of course!<br/><br/>On <span style='font-family: Lato Bold'>Slam Dunk Trivia Time</span> with Allie LaForce, we imagine a sports trivia sponsorship of yesteryear, complete with <span style='font-family: Lato Bold'>State Farm’s</span> comedic voice.",
    quote: ''
  },

  {
    theme: '06',
    visitUrl: 'https://www.original-influencers.com',
    // loop: 'https://courageous-cdn-media-bucket.s3.amazonaws.com/courageous-showcase-2022/EXPEDIA.mp4',
    // preview: invescoPreview,
    poster: humanaStill,
    brand: 'Humana',
    title: 'The Original Influencers',
    description:
      'Turning 65 is often described as a finish line, but any senior today will be happy to set the record straight. They’re connecting, exploring, and engaging in adventures more than at any other time in human history.<br/><br/> For Robin, Gid, and Norma, dated myths about retirement age stand in sharp relief to their daily acts of athleticism, performance, and community service. See how life after 65 is just the beginning.',
    quote: ''
  },
  {
    theme: '07',
    video: '866082525',
    loop: 'https://courageous-cdn-media-bucket.s3.amazonaws.com/courageous-showcase-2023/DESTINATION+CANADA+LOOP.mp4',
    preview: desCanStill,
    poster: desCanStill,
    brand: 'Destination Canada',
    title: 'This is Canada: The Yukon Territory',
    description:
      'Canada is a country of wide open spaces and wide open hearts. Its incredible diversity of cultures, vibrant cities, unique activities, foods, and natural beauty invite visitors to see life from a whole new point of view.<br/><br/>Travel north with BenDeLaCreme, an award-winning drag performer and LGBTQIA+ advocate, as she explores the breathtaking landscapes of the Canadian Yukon—a wild and wonderful place infused with rich art, history, and culture.',
    quote: ''
  },
  {
    theme: '08',
    video: '890363209',
    // loop: 'https://courageous-cdn-media-bucket.s3.amazonaws.com/courageous-showcase-2023/DESTINATION+CANADA+LOOP.mp4',
    preview: cadillacStill,
    poster: cadillacStill,
    brand: 'Cadillac',
    title: 'And Just Like That',
    description:
      "During the Season 2 premiere of the Max Original series <span style='font-family: Lato Bold'>And Just Like That,</span> we shined a spotlight on the <span style='font-family: Lato Bold'>all-electric Cadillac Lyriq.</span><br><br>In this exclusive peek behind the scenes, series costume designers <span style='font-family: Lato Bold'>Molly Rogers</span> and <span style='font-family: Lato Bold'>Danny Santiago</span> describe how they craft layer upon layer of expressive detail that lend personality and depth for the show’s unforgettable characters.<br><br>Molly and Danny curate original outfits that echo the Lyriq's sleek, futuristic style, and perfectly encapsulate Cadillac's 'be iconic' ethos for design and innovation.",
    quote: ''
  },
  {
    theme: '09',
    video: '853481539',
    // loop: 'https://courageous-cdn-media-bucket.s3.amazonaws.com/courageous-showcase-2022/INTELAWS.mp4',
    preview: sleepNumberStill,
    poster: sleepNumberStill,
    brand: 'Sleep Number',
    title: 'True Potential: Living Traditions',
    description:
      "Every year, tribes from all across North America gather for the <span style='font-family: Lato Bold'>Indigenous Games</span>  to help front-line leaders expose native children to recreational and sports activities that reflect their rich cultural heritage.<br/><br/>John Neptune, a coach and mentor from the Penobscot Nation, is preparing his team to compete in the North American Indigenous Games.<br/><br/>See how quality sleep plays an integral role in an active, healthy lifestyle, and helps to unlock your full potential.",
    quote: ''
  },
  {
    theme: '10',
    video: '894307770',
    loop: 'https://courageous-cdn-media-bucket.s3.amazonaws.com/courageous-showcase-2023/EXPEDIA+LOOP.mp4',
    preview: expediaStill,
    poster: expediaStill,
    brand: 'Expedia',
    title: 'Yucatán in One Breath',
    description:
      'The Yucatán Peninsula in Mexico hosts thousands of deep underground wells or sinkholes called cenotes across its storied landscape. Few know them better than Camila Jaber, a champion free diver who can swim to depths beyond 260 feet.<br/><br/>Camila takes us on an epic journey through an undersea sculpture park near the Mayan Riviera to one of Yucatán’s most photogenic magical towns, called Pueblos Mágicos.</br><br/>Get ready for an unforgettable travel experience, above the ground and under the sea.',
    quote: ''
  },

  {
    theme: '11',
    video: '806941930',
    // loop: 'https://courageous-cdn-media-bucket.s3.amazonaws.com/courageous-showcase-2022/morgan-stanley.mp4',
    // visitUrl: 'https://sponsorcontent.cnn.com/interactive/morganstanley/creating-space/',
    preview: jaguarStill,
    poster: jaguarStill,
    brand: 'Jaguar',
    title: 'In the Light of the Night',
    description:
      'Every photographer’s craft depends on capturing their subject in the perfect light. It’s why the whole industry revolves around deep investment in countless flashes, reflectors, and lighting instruments.<br/><br/>But veteran photographer Lindsey Byrnes, whose work regularly graces movie posters and magazine covers, is up for a new challenge: capturing the Jaguar F-PACE SVR, through only the available colors and lights of Miami at night.',
    quote: ''
  },
  {
    theme: '12',
    video: '894320329',
    // loop: 'https://courageous-cdn-media-bucket.s3.amazonaws.com/courageous-showcase-2022/DCHUB.mp4',
    // visitUrl: 'https://sponsorcontent.cnn.com/interactive/destination-canada/this-is-canada/',
    preview: intelStill,
    poster: intelStill,
    brand: 'Intel',
    title: 'Teaching AI to Learn & Behave',
    description:
      'What can AI reveal about the inner workings of the human brain?<br><br>For game-design legend Will Wright, understanding how humans engage in continuous learning and catalog memories is key to building new games with stunning complexity and personal depth.<br><br>See how a partnership between Intel and Numenta is revolutionizing game design by building an AI that can learn and behave like its players.',
    quote: ''
  }
  // {
  //   theme: '13',
  //   video: '',
  //   // loop: 'https://courageous-cdn-media-bucket.s3.amazonaws.com/courageous-showcase-2022/intel-hub.mp4',
  //   // visitUrl: 'https://sponsorcontent.cnn.com/interactive/intel/',
  //   preview: intelHubStill,
  //   poster: intelHubStill,
  //   brand: 'Intel',
  //   title: 'Numenta',
  //   description:
  //     'When placed in the right hands, technology can change the world. That’s why Intel isn’t just crafting the technology of tomorrow, they’re working tirelessly to make tech a more equitable, inclusive, and accessible space than ever before.<br/><br/>In this series of films and articles, we’ll meet real innovators and explore game-changing programs that show us how wonderful the future can be when built on ingenuity, imagination, and determination.',
  //   quote: ''
  // }
];
